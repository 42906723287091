<template>  
    <div id="main">
        <PWAPromptComponent />

        <div class="w-100">
            <div class="loading-container d-flex flex-column align-items-center justify-content-center" v-if="globalStore.isLoading">
                <img src="@/assets/loading.gif" width="100" alt="Cargando...">
                <p class="text-muted">Espere un momento, por favor...</p>
            </div>

            <div class="row gx-0" v-else>
                <section :key="`loading-app-${globalStore.isLoading}`">
                    <div v-if="$route.meta.showNavbar" class="col-xl-2 col-lg-3 px-0 d-none d-lg-block h-100 sidebar">
                        <Header />
                    </div>

                    <div :class="$route.meta.showNavbar ? 'col-lg-9 col-xl-10 col-md-12 offset-lg-3 offset-xl-2' : 'col-12'" class="">
                        <header-top v-if="$route.meta.showNavbar" />

                        <main class="content" :class="$route.meta.showNavbar ? 'p-3' : 'p-0 content-no-spaces'">
                            <page-title-component />

                            <router-view v-slot="{ Component }">
                                <component :is="Component" />
                            </router-view>

                        </main>
                        <Footer v-if="$route.meta.showNavbar" />

                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onBeforeMount, onBeforeUnmount, watch } from 'vue'
    import { useCategoriesStore } from "./stores/categories";
    import { useGlobalStore } from './stores/globalStore'
    import Footer from "./components/layouts/Footer.vue";
    import Header from "./components/layouts/Header.vue";
    import HeaderTop from '@/components/layouts/HeaderTop.vue'
    import PageTitleComponent from '@/components/layouts/PageTitleComponent.vue'
    import PWAPromptComponent from './components/common/PWAPromptComponent.vue';

    const globalStore = useGlobalStore()
    const categoriesStore = useCategoriesStore()

    onBeforeMount( async() => {
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        if(!globalStore.onLine)
            return globalStore.setIsLoading(false)

        categoriesStore.fetchCategories()
        await globalStore.checkAuth()
        
        globalStore.setIsLoading(false)
        console.info("[App.vue] All resources loaded")   
    })

    onBeforeUnmount( () => {
        window.removeEventListener('online', updateOnlineStatus)
        window.removeEventListener('offline', updateOnlineStatus)
    })

    watch(() => globalStore.onLine, (n) => console.log(`[App.vue] Device is now ${ n ? 'Online' : 'Offline'}`));

    function updateOnlineStatus(e) {
        const { type } = e
        globalStore.setOnline(type === 'online')
    }

</script>

<style scoped>
    .content{
        height: 100%;
        min-height: 100vh;
        padding-bottom: 9rem!important;
    }
    .content-no-spaces {
        padding-bottom: 0!important;
        margin: 0!important;
    }
    .sidebar {
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
    }
    .loading-container {
        min-height: 100vh;
        align-items: center;
        text-align: center;
    }
</style>
