import { createRouter, createWebHistory } from 'vue-router'
import authenticatedRouter from './authenticatedRouter'
import authRouter from './authRouter'
import WelcomeView from '@/views/WelcomeView'
import FeaturedView from '@/views/FeaturedView'
import OnsaleView from '@/views/OnsaleView'
import BestSellersView from '@/views/BestSellersView'
import CheckoutView from '@/views/CheckoutView'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'welcome',
            meta: { 
                showNavbar: true,
                showBackButton: false,
                title: "",
                desc: "",
            },
            component: WelcomeView
        },
        {
            path: '/featured',
            name: 'featured',
            meta: { 
                showNavbar: true,
                showBackButton: false,
                title: "En liquidación",
                desc: "Descubre nuestra selección de productos en liquidación, cuidadosamente elegidos para ofrecerte lo mejor."
            },
            component: FeaturedView
        },
        {
            path: '/on-sale',
            name: 'onsale',
            meta: { 
                showNavbar: true,
                showBackButton: false,
                title: "Ofertas",
                desc: "Aprovecha nuestras increíbles ofertas en una amplia variedad de productos."
            },
            component: OnsaleView
        },
        {
            path: '/best-sellers',
            name: 'best-sellers',
            meta: { 
                showNavbar: true,
                showBackButton: false,
                title: "Más vendidos",
                desc: " Nuestra selección de productos más vendidos destaca por su calidad, funcionalidad y satisfacción garantizada. "
            },
            component: BestSellersView
        },
        {
            path: '/checkout',
            name: 'checkout',
            meta: { 
                showNavbar: true,
                showBackButton: true,
                title: "Finalizar compra",
                desc: "Completa el proceso de compra de manera rápida y segura. Revisa y ajusta los detalles de tu pedido."
            },
            component: CheckoutView
        },
        ...authRouter,
        ...authenticatedRouter,
        { 
            name: '404', 
            path: '/:catchAll(.*)', 
            redirect: '/',
            meta: { showNavbar: false },
        },
    ]
})

export default router
