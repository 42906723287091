<template>
    <header class="card mb-3 border-0" v-if="$route.meta.title">
        <div class="card-body py-4 d-flex align-items-center">
            <button 
                type="button" 
                class="btn btn-xs btn-light rounded-circle py-2 me-3" 
                v-if="$route.meta.showBackButton"
                @click="$router.back()">
                <i class="bi bi-arrow-left"></i>
            </button>
            <div>
                <h1 class="page-header-title mb-0">
                    {{ $route.meta.title }}
                </h1>
                <div class="d-none d-md-block page-header-subtitle text-muted mb-0">{{ $route.meta.desc }}</div>
            </div>

            <img v-if="!$route.meta.showBackButton" class="logo-img ms-auto ml-auto" src="@/assets/logo.png" @click="$router.push({name: 'welcome'})" />
        </div>
    </header>
</template>

<style scoped>
.logo-img {
    width: 200px;
    height: auto;
}
</style>