<template>
    <div class="card bg-light border-0 mb-2 shadow-sm" v-for="item in store.getCart" :key="item.id">
        <div class="card-body d-flex justify-content-start align-items-center p-2">
            <img class="me-2 image-product rounded" :src="item.image || item.images[0].src" :alt="item.name">
            <i class="bi bi-x text-secondary ms-auto pointer position-absolute top-0 end-0 mx-1" @click="store.removeFromCart(item.id)"></i>

            <div class="col">
                <div class="name-product">
                    <p class="fw-bold small mb-0">{{ item.name }}</p>
                </div>

                <div class="d-flex justify-content-between">
                    <p class="text-theme mb-0">{{ getPrice(item) }}</p>

                    <div class="d-flex justify-content-end">
                        <button 
                            class="btn btn-outline-light text-secondary btn-sm py-0 px-1 rounded-circle"
                            @click="store.removeQuantity(item.id)">
                            <i class="bi bi-dash"></i>
                        </button>
                        <span class="quantity-product px-2 m-0">{{ item.quantity }}</span>
                        <button 
                            class="btn btn-outline-light text-theme btn-sm py-0 px-1 rounded-circle"
                            @click="store.addToCart(item)">
                            <i class="bi bi-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { currencyFormatted } from "@/helpers/currency"
import { useCheckoutStore } from "@/stores/checkout"
const store = useCheckoutStore()

function getPrice(item) {
    return currencyFormatted(parseFloat(item.prices.price * item.quantity))
}
</script>

<style scoped>
    .image-product {
        height: 60px;
        width: 60px;
        object-fit: contain;
    }
</style>