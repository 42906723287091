import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { swalToast } from '@/helpers/swal'
import { useLocalStorage } from "@vueuse/core"
import { useRouter } from 'vue-router'
import server from '../api/server'
import { useCheckoutStore } from './checkout'

export const useGlobalStore = defineStore('globalStore', () => {
    const router = useRouter()
    const checkoutStore = useCheckoutStore()
    const auth = ref({})
    const status = ref('unauthorised')
    const isLoading = ref(true)
    const onLine = ref(navigator.onLine)
    const bearerToken = useLocalStorage('token', '')
    const favorites = useLocalStorage('favorites', [])
    const countries = ref([])

    /**
     * Retorna un booleano según el estado de la autenticación.
     * En caso de tener un token en localstorage hará un inicio de sesion
     * automatico.
     * 
     * @return bool
     */
     const checkAuth = async() => {
        if(!onLine.value)
            return false
            
        if(status.value === 'unauthorised') {
            if(!bearerToken.value) 
                return false

            try {
                const { data } = await server.get('/wp/v2/users/me')

                if(!data.id) 
                    return false

                const userData = {...data, ...data.profile}
                setAuth(userData, 'authorised') 
                setIsLoading(false)
                return true               
            } catch(err) {
                bearerToken.value = ''
                setAuth({}, 'unauthorised') 
                setIsLoading(false)
                return false
            }
        }

        return true
	}

    const setOnline = (value) => {
        onLine.value = value

        if(!value)
            router.push({name: 'offline'})
        else
            router.push({name: 'login'})
    }

    const setIsLoading = (value) => {
        isLoading.value = value
    }

    const submitLogin = async({username, password}) => {
        try {
            setIsLoading(true)
            const { data } = await server.post('/jwt-auth/v1/token', {username, password})
            const { token } = data
            
            if(!token) {
                return false
            }

            bearerToken.value = token
            checkoutStore.clearCheckout()
            const success = await checkAuth()

            if(success)
                router.push({ name: 'welcome' })

        } catch(err) {
            setIsLoading(false)
            swalToast(err.response.data.message, 'error')
            return false
        }
        
        return true
    }

    const submitRegister = async(payload) => {
        try {
            const { data } = await server.post('/stripe-payment-gateway/v1/users', payload)

            if(!data.status) {
                swalToast(data.message, 'error')
                return false
            }

            swalToast("Te has registrado correctamente", "success") //TODO Public endpoints /customers /products
            return true
        } catch(err) {
            swalToast(err.response.data.message, 'error')
            return false
        }        
    }

    const submitLostPassword = async(email) => {
        try {
            const { data } = await server.post('/auth/password/forget', { email })
            const { status, message } = data

            if(!status) {
                swalToast(message, 'error')
                return false
            }

            swalToast(message, 'success')

            return true
        } catch(err) {
            swalToast(err, 'error')
            return false
        }
    }

    const submitResetPassword = async(formData) => {
        try {
            const { data } = await server.post('/auth/password/reset', formData)
            const { status, message } = data

            if(!status) {
                swalToast(message, 'error')
                return false
            }

            swalToast(message, 'success')

            return true
        } catch(err) {
            swalToast(err, 'error')
            return false
        }
    }

    const setToFav = (id) => {
        const index = favorites.value.indexOf(id)

        if (index > -1) {
            favorites.value.splice(index, 1)
        } else {
            favorites.value.push(id)
        }
    }

    const logOut = () => {
        setAuth({}, 'unauthorised')
        checkoutStore.clearCheckout()
        bearerToken.value = null
        router.push({ name: 'login'} )
    }

    const setAuth = async(authArg, statusArg) => {
		auth.value      = authArg
		status.value    = statusArg
	}

    async function updateCustomer(args) {
        try {
            const {data} = await server.post(`/wc/v3/customers/${args.id}`, args)
            
            swalToast(data.message, 'success')
            return true
        } catch(err) {
            swalToast(err.response.data.message, 'error')
            return false
        }
    }

    async function fetchCountries() {
        try {
            const { data } = await server.get('/wc/v3/data/countries')
            countries.value = data
        } catch(err) {
            console.log(err, 'error')
            return false
        }
    }
    
    return { 
        auth: computed( () => auth.value ), 
        getToken: computed( () => bearerToken.value ),
        isLoading: computed( () => isLoading.value ), 
        onLine: computed( () => onLine.value ),
        status: computed( () => status.value ),
        favorites: computed( () => favorites.value ),
        countries: computed( () => countries.value),
        fetchCountries,
        updateCustomer,
        checkAuth,
        logOut,
        setIsLoading,
        setOnline,
        setToFav,
        submitLogin,
        submitLostPassword,
        submitRegister,
        submitResetPassword,
    }
})
