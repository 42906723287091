<template>
    <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2">
        <div class="card border-0 product-card">
            <div class="card-body text-center">
                <img class="rounded img-fluid product-image pointer" 
                    :src="product.image" 
                    alt="Product Image" 
                    @click="display = true">
                    
                <div class="pointer" @click="display = true">
                    <p class="mt-2 mb-1 fw-bold product-name">
                        {{product.name}}
                    </p>

                    <template v-if="globalStore.status === 'authorised'">
                        <span v-if="product.variations.length > 0" class="text-theme">Múltiples precios</span>
                        <div v-else>
                            <span class="text-muted text-decoration-line-through me-1 small" v-if="product.prices.sale_price">
                                {{ currencyFormatted(product.prices.regular_price) }}
                            </span>
        
                            <span class="text-theme" v-if="product.prices.sale_price">
                                {{ currencyFormatted(product.prices.sale_price) }}
                            </span>
                            <span class="text-theme" v-else>
                                {{ currencyFormatted(product.prices.regular_price) }}
                            </span>
                        </div>
                    </template>
                </div>
                
                <i v-if="globalStore.status === 'authorised'" class="bi mx-1 pointer text-theme position-absolute end-0 top-0" 
                    :class="isFav(product.id) ? 'bi-heart-fill' : 'bi-heart'"
                    @click="globalStore.setToFav(product.id)">
                </i>

            </div>
            
            <template v-if="globalStore.status === 'authorised'">
                <transition name="fade-product">
                    <button v-if="product.variations.length > 0" class="btn btn-outline-theme btn-sm rounded" @click="display = true">
                    Ver opciones <i class="bi bi-caret-right"></i>
                    </button>
                </transition>

                <transition name="fade-product">
                    <button v-if="!existOnCart && product.variations.length === 0" class="btn btn-outline-theme btn-sm rounded" @click="checkoutStore.addToCart(product)">
                    Añadir al carro <i class="bi bi-cart"></i>
                    </button>
                </transition>
                
                <transition name="fade-product">
                    <div v-if="existOnCart && product.variations.length === 0" class="d-flex p-1 shadow-lg border-top justify-content-end">
                        <span class="quantity-product small px-2 m-0"> 
                            <i class="bi bi-cart me-1"></i> 
                            <transition name="list">
                                <span class="fw-bold" :key="`key-${existOnCart.quantity}`">{{ existOnCart.quantity }}u</span>
                            </transition>
                        </span>
    
                        <button 
                            class="btn ms-auto me-1 text-theme btn-sm py-0 px-1 rounded-circle"
                            @click="checkoutStore.removeFromCart(product.id)">
                            <i class="bi bi-trash3"></i>
                        </button>
    
                        <button 
                            class="btn btn-theme me-1 btn-sm py-0 px-1 rounded-circle"
                            @click="checkoutStore.addToCart(product)">
                            <i class="bi bi-plus"></i>
                        </button>
                    </div>
                </transition>
            </template>

        </div>

        <product-info-modal :product="product" :display="display" @on-close="display = false" @add-to-cart="checkoutStore.addToCart" />
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useGlobalStore } from '@/stores/globalStore';
import { useCheckoutStore } from '@/stores/checkout'
import { currencyFormatted } from '@/helpers/currency';
import ProductInfoModal from './ProductInfoModal.vue';
import useFav from '@/composables/useFav'

const { isFav } = useFav()
const globalStore = useGlobalStore()
const checkoutStore = useCheckoutStore()
const display = ref(false)
const props = defineProps({
    product: {
        type: Object,
        required: true
    }
})

const existOnCart = computed(() => checkoutStore.getCart.find(el => el.id === props.product.id))

</script>

<style scoped>
.product-image {
    object-fit: cover;
    height: 120px;
}
.product-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>