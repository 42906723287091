import { defineStore } from 'pinia'
import { ref } from 'vue'
import server from '../api/server'
import { useGlobalStore } from './globalStore'

export const useOrdersStore = defineStore('ordersStore', () => {
    const isLoading = ref(false)
    const orders = ref([])
    const glStore = useGlobalStore()
    const defaultParams = {
        per_page: 25,
    }

    async function fetchProducts(params = {}) {
        if(isLoading.value)
            return

        try {
            isLoading.value = true
            params = { ...defaultParams, ...params }

            const { data } = await server.get(`/wc/v3/orders?customer=${glStore.auth.id}`, { params })
            orders.value = data
            isLoading.value = false
        } catch(err) {
            console.log(err, 'error')
            return false
        }
    }

    function setOrders(args) {
        orders.value = args
    }

    return { 
        isLoading,
        orders,    
        fetchProducts, 
        setOrders,
    }
})
