import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { swalToast, swalQuestionCustom } from '../helpers/swal'
import { useRouter } from 'vue-router'
import server from '../api/server'

export const useCategoriesStore = defineStore('categoriesStore', () => {
    const router = useRouter()
    const categories = ref([])
    const isLoading = ref(true)
    const currentCategory = ref({})
    const searchInput = ref('')
    const orderFilter = ref('asc')

    async function fetchCategories () {
        try {
            isLoading.value = true
            const { data } = await server.get(`/wc/v3/products/categories`)
            categories.value = data
            isLoading.value = false
        } catch(err) {
            console.log(err, 'error')
            return false
        }
    }

    function filter(event){
        const field = event.target.value
        categories.value.sort((a, b) => (a[field] < b[field]) ? -1 : 1)
    }

    function sortDirection(arg){
        orderFilter.value = arg
        categories.value.reverse()
    }

    function getcurrentCategory(id){
        if(id != 'new')
        {
            currentCategory.value = categories.value.find( el => el.id == id) 
        } else {
            currentCategory.value = {
                name: '',
                contact: '',
                file: null,
                id: 'new'
            }
        }
    }

    async function submitForm(data) {
        const formData = new FormData()

        formData.append('supplier_id', data.id)
        formData.append('name', data.name)
        formData.append('contact', data.contact)
        formData.append('email', data.email)
        formData.append('phone', data.phone)
        if(data.file) formData.append('image', data.file)

        const action = (data.id === 'new') ? '/categories' : '/categories/update'
        try {
            const { data } = await server.post(action, formData, { headers: { 'Content-Type': 'multipart/form-data' }})
            const { status, results } = data

            if(!status) return false

            if(data.id === 'new') {
                categories.value.unshift(results)
            } else {
                const update = categories.value.findIndex( el => el.id == data.id) 
                categories.value[update] = results
            }

            swalToast("El proveedor ha sido actualizado correctamente.")
            router.push({name: 'categories'})
        } catch(err) {
            console.log(err, 'error')
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
            });
            return false
        }
    }

    async function destroy(id) {
        const confirmed = await swalQuestionCustom("¿Deseas eliminar este proveedor?", "Los materiales vinculados a este proveedor no se verán afectados", "Eliminar", "Cancelar", "warning")
        if(!confirmed) return;

        try {
            const { data } = await server.delete(`categories/${id}`)
            const { status } = data

            if(!status) {
                console.log(err, 'error')
                return false
            }

            const index = categories.value.findIndex( el => el.id == id) 
            categories.value.splice(index, 1)
           
            swalToast("El proveedor ha sido eliminado correctamente.")
            return true
        } catch(err) {
            console.log(err, 'error')
            return false
        }
    }

    function setSearchInput(args) {
        searchInput.value = args
    }

    return { 
        currentCategory, 
        isLoading,
        orderFilter,
        searchInput,
        getCategories: computed( () => categories.value), 
        destroy,
        filter,
        fetchCategories, 
        getcurrentCategory,
        setSearchInput,
        sortDirection, 
        submitForm,
    }
})
