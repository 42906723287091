<template>
    <div class="row gx-3 gy-3">
        <template v-if="!prStore.isLoading">
            <transition-group name="fade" appear>
                <slider-home v-if="!prStore.isCategorySelected" key="slider" />

                <div v-else class="row my-2" key="logo-image">
                    <img class="logo-img" src="@/assets/logo.png" />
                </div>

                <product-card v-for="item in prStore.products" :product="item" :key="`product-${item.id}`" />

                <div class="no-results text-center py-5" v-if="prStore.products.length === 0">
                    <img src="@/assets/images/supermarket.png" alt="Supermarket">
                    <p class="text-muted">No se han encontrado productos...</p>
                </div>
            </transition-group>
        </template>

        <loading-container v-else />
    </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useProductsStore } from '@/stores/products';
import LoadingContainer from '@/components/common/LoadingContainer.vue';
import ProductCard from '@/components/products/ProductCard.vue';
import SliderHome from '@/components/common/SliderHome.vue'

const prStore = useProductsStore()

onBeforeMount( () => {
    prStore.fetchProducts()
})
</script>

<style scoped>
.no-results > img {
    max-width: 350px;
    width: 100%;
}
.logo-img {
    width: 300px;
    height: auto;
    display: block;
    margin: auto;
}
</style>