import { useGlobalStore } from "@/stores/globalStore"

export const getRealPrice = (item) => {
	const globalStore = useGlobalStore()
	const authRole = globalStore.auth.user_role	
	const authId = globalStore.auth.id	

	const customerPricing = item.meta_data.find(el => el.key === '_customer_based_pricing_rules')
	if(customerPricing) {
		const price =  customerPricing.value[authId]

		if(price) {
			return {
				regular_price: parseFloat(price.regular_price),
				sale_price: parseFloat(price.sale_price)
			}
		}
	}

	const pricingRules = item.meta_data.find(el => el.key === '_role_based_pricing_rules')
	if(!pricingRules) {
		return {
			regular_price: parseFloat(item.regular_price),
			sale_price: parseFloat(item.sale_price)
		}
	}

	const rolePrice = pricingRules.value[authRole]
	
	if(!rolePrice) {
		return {
			regular_price: parseFloat(item.regular_price),
			sale_price: parseFloat(item.sale_price)
		}
	}

	return {
			regular_price: parseFloat(rolePrice.regular_price),
			sale_price: parseFloat(rolePrice.sale_price)
	}
}

export const getTaxImport = (prd, price) => {
	const { tax_status, tax_class } = prd

	if(tax_status === 'none') 
		return 0

	if(tax_class === 'super-reducido-5') {
		return price * 0.05
	} else if (tax_class == 'super-reducido-4') {
		return price * 0.04
	} else if (tax_class == 'reducido') {
		return price * 0.10
	} else if (tax_class == '0-impuesto') {
		return price * 0
	} else {
		return price * 0.21
	}
}