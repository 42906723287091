<template>
    <div class="col-sm-12 col-md-8 col-lg-5">
        <div class="card border-0">
            <div class="card-body p-3">
                <div class="d-flex justify-content-start mb-3">
                    <img width="100" height="100" class="user-avatar rounded" src="@/assets/images/supermarket.png" alt="User Avatar">
            
                    <div class="ms-2">
                        <h3 class="fw-bold text-capitalize">{{ global.auth.first_name}} {{ global.auth.last_name}}</h3>
                        <p class="text-muted mb-0">{{ global.auth.email }}</p>
                        <router-link :to="{name:'profile'}" class="p-0 btn btn-link text-dark">
                            Modificar perfil
                            <i class="ms-1 bi bi-chevron-right"></i>
                        </router-link>
                    </div>
                </div>

                <account-links />

            </div>
        </div>
        
    </div>
</template>

<script setup>
import { useGlobalStore } from '@/stores/globalStore';
import AccountLinks from '@/components/account/AccountLinks.vue';

const global = useGlobalStore()
</script>

<style scoped>
.btn-rounded {
    border-radius: 1.5em;
    padding: 10px 20px;
}
</style>