<template>
    <div class="col-sm-12 col-md-8 col-lg-5">
        <div class="card border-0">
            <div class="card-body px-4">
                <h4 class="my-3">Actualizar los datos de tu perfil</h4>
                <hr>
                <form class="needs-validation" @submit.prevent="handleSubmit()">
                    <div class="row g-3 mb-3">
                        <div class="col-sm-6">
                            <label for="firstName" class="form-label">Nombre</label>
                            <input type="text" class="form-control" id="firstName" placeholder="" v-model="formData.billing.first_name" required="">
                        </div>

                        <div class="col-sm-6">
                            <label for="lastName" class="form-label">Apellidos</label>
                            <input type="text" class="form-control" id="lastName" placeholder="" v-model="formData.billing.last_name" required="">
                        </div>

                        <div class="col-12">
                            <label for="email" class="form-label">Correo electrónico</label>
                            <input type="email" class="form-control" id="email" placeholder="you@example.com" v-model="formData.email">
                        </div>
                    </div>
                    
                    <button type="submit" class="mt-3 btn-expanded w-100 btn btn-theme" :disabled="isLoading ? true : false">
                        <span>Actualizar campos</span>

                        <div v-if="isLoading" class="spinner-border me-1 ms-3 spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Cargando</span>
                        </div>
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { useGlobalStore } from "@/stores/globalStore";

const emits = defineEmits(['on-submit'])
const store = useGlobalStore()
const formData = ref({})
const isLoading = ref(false)

onBeforeMount( () => {
    formData.value = { ...store.auth }

    if(Object.keys(formData.value).length == 0) {
        formData.value = {
            billing: {}
        }
    }
})

async function handleSubmit() {
    if(formData.value) {
        isLoading.value = true
        const resp = await store.updateCustomer(formData.value)
        isLoading.value = false
        emits('on-submit', resp)
    }
}
</script>

<style scoped>
    .btn-expanded {
        width: 100%;
        padding: 16px!important;
        border-radius: 16px!important;
    }
</style>