<template>
    <div>
        <template v-if="!store.isLoading">
                <ol v-if="store.orders.length > 0" class="list-group">
                    <transition-group name="fade" appear>
                        <orders-list v-for="item in store.orders" :order="item" :key="`order-${item.id}`" />
                    </transition-group>
                </ol>

                <div class="no-results text-center py-5" v-if="store.orders.length === 0">
                    <img src="@/assets/images/supermarket.png" class="w-100" alt="Supermarket">
                    <p class="text-muted">No se han encontrado pedidos...</p>
                </div>
        </template>

        <loading-container v-else />
    </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useOrdersStore } from '@/stores/orders';
import OrdersList from '@/components/orders/OrdersList.vue'
import LoadingContainer from '@/components/common/LoadingContainer.vue';

const store = useOrdersStore()
onBeforeMount( async() => {
    await store.fetchProducts()
})
</script>

<style scoped>
.btn-rounded {
    border-radius: 1.5em;
    padding: 10px 20px;
}
.no-results > img {
    max-width: 350px;
    width: 100%;
}
</style>