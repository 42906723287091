<template>
    <div class="row gx-3 gy-3">
        <template v-if="!prStore.isLoading">
            <transition-group name="fade" appear>
                <product-card v-for="item in prStore.products" :product="item" :key="`product-${item.id}`" />

                <div class="no-results text-center py-5" v-if="prStore.products.length === 0">
                    <img src="@/assets/images/supermarket.png" alt="Supermarket">
                    <p class="text-muted">No se han encontrado productos...</p>
                </div>
            </transition-group>
        </template>

        <loading-container v-else />
    </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useProductsStore } from '@/stores/products';
import LoadingContainer from '@/components/common/LoadingContainer.vue';
import ProductCard from '@/components/products/ProductCard.vue';

const prStore = useProductsStore()

onBeforeMount( () => {
    prStore.fetchProducts({ on_sale: true })
})
</script>

<style scoped>
.no-results > img {
    max-width: 350px;
    width: 100%;
}
</style>