import { useGlobalStore } from '@/stores/globalStore';
import axios from 'axios'

const serverURL = process.env.VUE_APP_SERVER_API_URL
const wc_public_key = process.env.VUE_APP_WC_PUBLIC_KEY
const wc_secret_key = process.env.VUE_APP_WC_SECRET_KEY

const server = axios.create({
    baseURL: serverURL,
})

server.interceptors.request.use( function(config) {
    const store = useGlobalStore()
    config.headers.Accept = 'application/json'

    // Agrega claves publicas a las peticiones de WooCommerce. 
    if(config.url.includes('/wc/')) {
        config.headers.Authorization = `Basic ${ btoa(wc_public_key + ':' + wc_secret_key) }`
        return config
    }

    if(store.getToken) {
        config.headers.Authorization = `Bearer ${store.getToken}`
        return config
    }

    return config
})

export default server