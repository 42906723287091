<template>
    <div class="text-center p-5" v-if="store.getCart.length === 0">
        <img class="img-fluid mb-3" src="@/assets/images/empty-cart.png" alt="Empty cart">
        <p>El carrito está vacío...</p>
    </div>
</template>

<script setup>
import { useCheckoutStore } from "@/stores/checkout"
const store = useCheckoutStore()

function getPrice(item) {
    return parseFloat(item.price * item.quantity)
}
</script>

<style scoped>
    .image-product {
        height: 70px;
        width: 70px;
        object-fit: cover;
    }
</style>