<template>
    <Transition name="fade" appear>
        <div v-if="!catStore.isLoading">
            <ul class="navbar-nav px-3 flex-column" v-if="!catStore.isLoading">
                <span class="text-muted small mb-2 text-uppercase d-flex justify-content-between">
                    <span>Categorías</span>
                    <i class="bi bi-three-dots"></i>
                </span>
                <li class="nav-item mx-1 mb-0" v-for="item in catStore.getCategories" :key="item.id">
                    <a class="nav-link pointer py-2" 
                        :class="item.id === selected ? 'active' : ''"
                        @click="selectCategory(item.id)">

                        <img v-if="item.image" class="cat-image" :class="item.id != selected ? 'grayscaled' : ''" :src="item.image.src" :alt="item.image.name">
                        <i v-else class="bi bi-house"></i>

                        <span class="px-2">{{ item.name }}</span>
                    </a>
                </li>
            </ul>  
        </div>
    </Transition>
</template>

<script setup>
import { useCategoriesStore } from "@/stores/categories";
import { useProductsStore } from "@/stores/products";
import { ref } from "vue";
import { useRouter } from "vue-router";

const emits = defineEmits(['on-select'])
const catStore = useCategoriesStore()
const prStore = useProductsStore()
const selected = ref(0)
const router = useRouter()

const selectCategory = async(catId) => {
    if(prStore.isLoading)
        return
    
    router.push({name:'welcome'})
    selected.value = catId
    prStore.fetchProducts({category: catId})
    emits('on-select')
}
</script>

<style scoped>
    .navbar-nav .nav-link.active, 
    .navbar-nav .nav-link.active > span,
    .navbar-nav .show > .nav-link {
        color: #ed8600!important;
        font-weight: bold!important;
    }
    .navbar-nav .nav-link {
        font-size: 17px;
    }

    .cat-image {
        width: 17px;
        height: 17px;
        object-fit: cover;
    }

    .grayscaled {
        filter: grayscale()
    }
</style>