<template>
    <transition name="fade" appear>
        <div class="modal in" :key="`order-${order.id}-${display}`" :class="display ? 'd-block show fade' : 'd-none'" tabindex="-1" @click.self="closeModal()">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content border-0 shadow-lg">
                    <div class="modal-header border-0 shadow-sm">
                        <div class="modal-title">Pedido #{{ order.id }} <span :class="`badge bg-${order.status} rounded-pill text-capitalize`">{{order.status}}</span></div>
                        
                        <button type="button" 
                            class="btn py-2 btn-outline-secondary rounded-circle" 
                            @click="closeModal()">
                            <i class="bi bi-x"></i>
                        </button>
                    </div>

                    <div class="modal-body pb-5">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <h3>Productos</h3>
                                <ul class="list-group list-group-flush products-container">
                                    <li v-for="item in order.line_items" class="list-group-item d-flex align-items-center" :key="item.id">
                                        <img class="product-image me-3" :src="item.image.src" :alt="item.name">
                                        <div class="product-info">
                                            <p class="mb-0 h5">{{ item.name }} <span class="text-muted">x{{ item.quantity }}</span></p>
                                            <p class="mb-0 text-theme fw-bold">{{ currencyFormatted(parseFloat(item.subtotal)) }}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <h3>Detalles de pedido #{{ order.id }}</h3>
                                <div class="mb-2">
                                    <p class="text-uppercase mb-0">Fecha</p>
                                    <span class="fw-bold">{{ dateFormatted(order.date_created) }}</span>
                                </div>
                                <div class="mb-2">
                                    <p class="text-uppercase mb-0">Email</p>
                                    <span class="fw-bold">{{ order.billing.email }}</span>
                                </div>
                                <div class="mb-2" v-if="parseFloat(order.discount_total) > 0">
                                    <p class="text-uppercase mb-0">Descuento aplicado</p>
                                    <span class="fw-bold text-success">-{{ currencyFormatted(parseFloat(order.discount_total)) }}</span>
                                </div>
                                <div class="mb-2" v-if="order.shipping_lines.length > 0">
                                    <p class="text-uppercase mb-0">Gastos de envío</p>
                                    <span class="fw-bold me-1" v-for="item in order.shipping_lines" :key="item.id">{{ item.title }}</span>
                                    <span class="fw-bold">{{ currencyFormatted(parseFloat(order.shipping_total)) }}</span>
                                </div>
                                <div class="mb-2">
                                    <p class="text-uppercase mb-0">Total</p>
                                    <span class="fw-bold me-1">{{ currencyFormatted(parseFloat(order.total)) }}</span>
                                    <small>impuestos incluidos</small>
                                </div>

                                <button class="btn btn-outline-theme" @click="repeatOrder">Repetir pedido</button>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
import { currencyFormatted } from '@/helpers/currency';
import { dateFormatted } from '@/helpers/moment';
const emits = defineEmits(['on-close', 'on-repeat'])
const props = defineProps({
    display: {
        required: true,
        type: Boolean,
    },
    order: {
        required: true,
        type: Object,
    }
})

function repeatOrder() {
    console.log(props.order.line_items)
    const elements = props.order.line_items.map(element => ({
        ...element,
        images: [element.image],
        image: element.image.src
    }))
    emits('on-repeat', elements)
}

function closeModal() {
    emits('on-close') // TODO Fix
}
</script>

<style scoped>
.product-image {
    height: 50px;
    width: 50px;
    object-fit: cover;
}
.products-container {
    overflow-y: scroll;
    max-height: 275px
}
</style>