<template>
    <div class="row gx-3 gy-3">
        <template v-if="!loading">
            <checkout-cart-preview v-if="!order" />

            <checkout-form-data v-if="!order" @on-submit="handleCheckout" />

            <div v-if="order" class="col-md-7 col-lg-8 order-md-first">
                <div class="card border-0">
                    <div class="card-body px-4">
                        <h4 class="my-3">Gracias por su pedido!</h4>
                        <p>Tu pedido ha sido generado correctamente. Ahora, por favor procede con el pago para completar tu compra.</p>
                        
                        <button 
                            type="button" 
                            class="btn btn-theme btn-expanded d-flex shadow-lg justify-content-center align-items-center"
                            @click="handlePayment">
                            Proceder con el pago {{ currencyFormatted(parseFloat(order.total) || 0.00) }}
                            <i class="ms-3 bi bi-arrow-right"></i>
                        </button>
                        <hr>
                        
                        <div class="d-flex justify-content-around align-items-center mt-4 mb-3" v-if="order">
                            <div>
                                <p class="mb-0 fw-bold">{{ currencyFormatted(parseFloat(order.total) || 0.00) }}</p>
                                <p class="mb-0 small">Importe</p>
                            </div>

                            <div>
                                <p class="mb-0 fw-bold">{{ dateFormatted(order?.date_created.date) }}</p>
                                <p class="mb-0 small">Fecha</p>
                            </div>

                            <div>
                                <p class="mb-0 fw-bold text-capitalize">Procesando</p>
                                <p class="mb-0 small">Estado</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </template>

        <loading-container v-else />
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import { useCheckoutStore } from '@/stores/checkout';
import { useGlobalStore } from '@/stores/globalStore';
import { useRouter } from 'vue-router';
import { currencyFormatted } from '@/helpers/currency';
import { dateFormatted } from '@/helpers/moment';
import CheckoutCartPreview from '@/components/checkout/CheckoutCartPreview.vue'
import CheckoutFormData from '@/components/checkout/CheckoutFormData.vue'
import LoadingContainer from '@/components/common/LoadingContainer.vue';

const chStore = useCheckoutStore()
const glStore = useGlobalStore()
const router = useRouter()
const loading = ref(true)
const order = ref(null)

onBeforeMount( async() => {
    loading.value = true

    if(chStore.getCart.length <= 0) {
        return router.push({name: 'welcome'})
    }

    await glStore.fetchCountries()
    await chStore.fetchShippingZones()
    loading.value = false
})

async function handleCheckout(data) {
    order.value = data
}

async function handlePayment() {
    const PAYMENT_URL = `https://almomento.cat/finalizar-compra/order-pay/${order.value.id}/?pay_for_order=true&key=${order.value.order_key}`
    router.push({name: 'welcome'})
    chStore.clearCheckout()
    window.location.href = PAYMENT_URL
}
</script>