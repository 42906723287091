<template>
    <div class="dropdown px-3" v-if="globalStore.status === 'authorised'">
        <a href="#" class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
            <strong class="text-capitalize" v-if="globalStore.auth.name">{{globalStore.auth.name}}</strong>
            <img :src="globalStore.auth.avatar_urls['96']" alt="" width="32" height="32" class="rounded-circle me-2 ms-auto">
        </a>
        <ul class="dropdown-menu text-small shadow border-0 px-3" aria-labelledby="dropdownUser2">
            <li><router-link class="dropdown-item" :to="{name: 'account'}">Ajustes</router-link></li>
            <li><router-link class="dropdown-item" :to="{name: 'profile'}">Mi perfil</router-link></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="javascript:void(0)" @click="globalStore.logOut()">Cerrar sessión</a></li>
        </ul>
    </div>

    <ul class="navbar-nav px-3 flex-column" v-if="globalStore.status === 'unauthorised'">
        <span class="text-muted small mb-2 text-uppercase">
            <span>Autenticación</span>
        </span>
        <li class="nav-item mx-1 mb-1">
            <router-link :to="{name: 'login'}" class="nav-link pointer">
                <i class="bi bi-unlock text-theme"></i>
                <span v-if="!globalStore.isLoading" class="px-2">Iniciar sesión</span>
                <span v-if="globalStore.isLoading" class="px-2">Iniciando sesión...</span>
            </router-link>
        </li>
    </ul>
</template>

<script setup>
import {useGlobalStore} from '../../stores/globalStore'
const globalStore = useGlobalStore()
</script>