<template>
    <transition name="list" appear>
        <div class="modal right in" :class="display ? 'd-block show fade' : 'd-none'" tabindex="-1" @click.self="closeModal()">
            <div class="modal-dialog">
                <div class="modal-content border-0 shadow-lg">
                    <div class="modal-header border-0 shadow-sm">
                        <div class="modal-title">
                            <h5 class="mb-2 fw-bold">Carrito</h5>
                            <p class="text-muted small h6">Mi pedido</p>
                        </div>
                        
                        <button 
                            type="button" 
                            class="btn py-2 btn-outline-secondary rounded-circle" 
                            @click="closeModal()">
                            <i class="bi bi-x"></i>
                        </button>
                    </div>

                    <div class="modal-body">
                        <shipping-cost-notice />
                        <cart-items />
                        <cart-empty />
                    </div>

                    <div class="modal-footer border-0">
                        <div class="d-block w-100">
                            <cart-checkout @navigate-checkout="goToCheckout()" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
import CartItems from './CartItems'
import cartCheckout from './CartCheckout'
import CartEmpty from './CartEmpty.vue'
import ShippingCostNotice from './ShippingCostNotice.vue';
import { useRouter } from 'vue-router'

const router = useRouter()
const emits = defineEmits(['on-close'])
const props = defineProps({
    display: {
        required: true,
        type: Boolean,
    }
})

function closeModal() {
    emits('on-close')
}

function goToCheckout() {
    emits('on-close')
    router.push({name: 'checkout'})
}

</script>

<style scoped>
.modal-body{
    overflow-y: scroll
}
</style>