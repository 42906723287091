<template>
    <div class="py-3 bg-white h-100">
        <div class="d-flex flex-column flex-shrink-0 scrollable">
            <router-link class="mb-0 px-2 text-decoration-none d-flex justify-content-center" :to="{ name: 'welcome' }">
                <img class="logo-img" src="@/assets/logo.png"/>
            </router-link>

            <hr class="sidebar-divider">

            <div>
                <header-link 
                class="px-3"
                :items="navbar" 
                @on-change="selectRoute" />
                <hr class="sidebar-divider">
                
                <template v-if="!catStore.isLoading">
                    <header-categories />
                    <hr class="sidebar-divider"> 
                </template>

                <header-auth-shortcut />
            </div>
            

        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import HeaderLink from '@/components/common/HeaderLink.vue'
import HeaderCategories from '@/components/categories/HeaderCategories.vue'
import HeaderAuthShortcut from '../auth/HeaderAuthShortcut.vue'
import { useCategoriesStore } from '@/stores/categories'

const router = useRouter()
const catStore = useCategoriesStore()

const selectRoute = (item) => {
    navbar.value.map( item => {
        item.active = false
        return item
    })

    item.active = true
    router.push({ name: item.route })
}

const navbar = ref([
    {
        route: 'welcome',
        label: 'Catálogo',
        active: true,
        icon: 'bi bi-basket2',
    },
    {
        route: 'onsale',
        label: 'Promociones',
        active: false,
        icon: 'bi bi-percent',
    },
    {
        route: 'best-sellers',
        label: 'Más vendido',
        active: false,
        icon: 'bi bi-graph-up-arrow',
    },
    {
        route: 'featured',
        label: 'En liquidación',
        active: false,
        icon: 'bi bi-star',
    },
])
</script>

<style scoped>
.logo-img {
    max-width: 100%;
    max-height: 70px;
}
.scrollable {
    overflow-y: scroll;
    height: 100%;
}
</style>