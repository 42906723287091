<template>
    <ul class="list-group rounded-0">
        <li class="list-group-item border-0 list-group-item-light text-uppercase small">
            Contenido
        </li>
        <router-link v-slot="{ href, navigate }" :to="{name: 'favs'}" class="text-decoration-none">
            <li class="list-group-item border-0 align-items-center pointer d-flex py-1" :href="href" @click="navigate">
                <i class="bi bi-heart text-theme me-2"></i>
                Favoritos
                <button class="btn btn-link text-dark ms-auto">
                    <i class="bi bi-chevron-right"></i>
                </button>
            </li>
        </router-link>
        <router-link v-slot="{ href, navigate }" :to="{name: 'orders'}" class="text-decoration-none">
            <li class="list-group-item border-0 align-items-center pointer d-flex py-1" :href="href" @click="navigate">
                <i class="bi bi-clock-history text-theme me-2"></i>
                Compras
                <button class="btn btn-link text-dark ms-auto">
                    <i class="bi bi-chevron-right"></i>
                </button>
            </li>
        </router-link>
        <li class="list-group-item border-0 list-group-item-light text-uppercase small">
            Preferencias
        </li>
        <li class="list-group-item border-0 align-items-center d-flex py-1">
            <i class="bi me-2 text-theme" :class="notificationInput ? 'bi-bell' : 'bi-bell-slash'"></i>
            Notificaciones
            <div class="ms-auto form-check form-switch">
                <input class="form-check-input pointer" v-model="notificationInput" type="checkbox" role="switch" id="switch-notification" checked>
            </div>
        </li>
        <li class="list-group-item border-0 align-items-center pointer d-flex py-1" @click="global.logOut()">
            <i class="bi bi-escape text-theme me-2"></i>
            Cerrar sesión
            <button class="btn btn-link text-dark ms-auto">
                <i class="bi bi-chevron-right"></i>
            </button>
        </li>
    </ul>
</template>

<script setup>
import { useGlobalStore } from '@/stores/globalStore';
import { ref } from 'vue';
const global = useGlobalStore()

const notificationInput = ref(true)
</script>

<style scoped>
.list-group-item {
    min-height: 50px;
    align-items: center;
    display: flex;
}
.list-group-item-light {
    letter-spacing: 2px;
    font-weight: 600;
}
</style>