<template>
    <div>
        <profile-form @on-submit="handleSubmit" />
    </div>
</template>

<script setup>
import ProfileForm from '@/components/account/ProfileForm.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

function handleSubmit(data) {
    if(!data)
        return

    router.push({name: 'account'})
}
</script>