import { useGlobalStore } from '../../stores/globalStore'

const isAuth = async( to, from, next) => {
    const globalStore = useGlobalStore()

    /* This code block is checking if the user is online and if they have a valid token. If the user is
     * not online or does not have a valid token, it will display an error message
     */
    if(!globalStore.onLine) {
        if(globalStore.status !== 'authorised') {
            return next({ name: 'login' })
        }
        return next()
    }
    
    /* Check if the user is authenticated or authorized to access the current route. */
    await globalStore.checkAuth()

    if (globalStore.status !== 'authorised') {
        return next({ name: 'login' })
    }
    next()
}

export default isAuth