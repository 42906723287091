<template>
    <transition name="fade" appear>
        <div class="card position-absolute w-100 border-0 shadow rounded results-container" v-if="items.length > 0">
            <div class="card-body">
                <div class="card bg-light border-0 mb-2" v-for="item in items" :key="item.id">
                    <div class="card-body d-flex justify-content-start align-items-center p-0 pointer">
                        <img class="me-3 image-product rounded" :src="item.images[0].src" :alt="item.name" @click="viewInfo(item)">
                        
                        <div class="col">
                            <div class="d-flex justify-content-between align-items-center">
                                <div @click="viewInfo(item)">
                                    <p class="fw-bold small mb-0">{{ item.name }}</p>
                                    <p class="text-theme mb-0" v-if="global.status === 'authorised'">{{ currencyFormatted(parseFloat(item.price)) }}</p>
                                </div>

                                <div class="text-end" v-if="global.status === 'authorised'">
                                    <div class="d-flex justify-content-end">
                                        <button 
                                            v-if="chStore.getCart.find(el => el.id === item.id)?.quantity"
                                            class="btn btn-outline-light text-secondary py-0 px-1 rounded-circle"
                                            @click="chStore.removeQuantity(item.id)">
                                            <i class="bi bi-dash"></i>
                                        </button>

                                        <button 
                                            class="btn btn-outline-light text-theme py-0 px-1 rounded-circle"
                                            @click="chStore.addToCart(item)">
                                            <i class="bi bi-plus"></i>
                                        </button>
                                    </div>

                                    <span class="quantity-product px-2" :class="chStore.getCart.find(el => el.id === item.id)?.quantity ? 'text-theme' : 'text-secondary'">
                                        <i class="bi bi-cart me-1"></i>
                                        <span class="fw-bold">
                                            {{ chStore.getCart.find(el => el.id === item.id)?.quantity || '0' }}<span class="small">u</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <product-info-modal v-if="display" :product="product" :display="display" @on-close="closeModal()" @add-to-cart="chStore.addToCart" />
</template>

<script setup>
import { currencyFormatted } from "@/helpers/currency"
import { ref } from "vue";
import { useCheckoutStore } from "@/stores/checkout";
import ProductInfoModal from '@/components/products/ProductInfoModal.vue';
import { useGlobalStore } from "@/stores/globalStore";

const chStore = useCheckoutStore()
const global = useGlobalStore()
const emits = defineEmits(['added-to-cart'])
const display = ref(false)
const product = ref(null)
const props = defineProps({
    items: {
        required: true
    }
})

function viewInfo(item) {
    product.value = item
    display.value = true
    emits('added-to-cart')
}

function closeModal() {
    display.value = false
    product.value = null
}
</script>

<style scoped>
    .image-product {
        height: 70px;
        width: 70px;
        object-fit: contain;
    }
    .results-container {
        overflow-y: scroll;
        max-height: 300px;
        z-index: 99;
    }
</style>