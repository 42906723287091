import server from '@/api/server'
import { ref } from 'vue'

export default function useSearch() {
    const isLoading = ref(false)

    async function fetchSearch(input) {
        if(isLoading.value)
            return

        try {
            isLoading.value = true
            
            const { data } = await server.get('/wc/v3/products', {
                params: { search: input }
            })
        
            isLoading.value = false
            return data
        } catch(error) {
            isLoading.value = false
            return []
        }
    }

    return {
        fetchSearch,
        isLoading
    }
}