import AccountView from '@/views/AccountView'
import EditProfileView from '@/views/Account/EditProfileView'
import FavoritesView from '@/views/Account/FavoritesView'
import isAuth from './guards/isAuth'
import OrdersView from '@/views/Account/OrdersView'

export default [
    {
        path: '/account',
        name: 'account',
        beforeEnter: [isAuth],
        meta: { 
            showNavbar: true,
            showBackButton: false,
            title: "Mi cuenta",
            desc: "Aquí podrás acceder y gestionar tu perfil, así como personalizar tus preferencias y configuraciones según tus necesidades."
        },
        component: AccountView
    },
    {
        path: '/account/favs',
        name: 'favs',
        meta: { 
            showNavbar: true,
            showBackButton: true,
            title: "Favoritos",
            desc: "En esta sección, guarda y accede fácilmente a tus productos marcados como favoritos."
        },
        component: FavoritesView
    },
    {
        path: '/account/orders',
        name: 'orders',
        meta: { 
            showNavbar: true,
            showBackButton: true,
            title: "Mis compras",
            desc: "Haz un seguimiento de todas tus compras anteriores. Obtén detalles sobre tus pedidos, como fechas y productos adquiridos, todo en un solo lugar para mayor conveniencia."
        },
        component: OrdersView
    },
    {
        path: '/account/profile',
        name: 'profile',
        meta: { 
            showNavbar: true,
            showBackButton: true,
            title: "Mi perfil",
            desc: "Obtén detalles sobre tu perfil, como direcciones de envío y de facturación."
        },
        component: EditProfileView
    },
]