import { useGlobalStore } from '@/stores/globalStore'

export default function useFav() {
    const store = useGlobalStore()

    function isFav(id) {
        if(store.favorites.includes(id))
            return true

        return false
    }

    return {
        isFav,
    }
}