export default [
    {
        path: '/auth/login',
        name: 'login',
        meta: { showNavbar: false },
        component: () => import('../views/LoginView.vue')
    },
    // {
    //     path: '/auth/register',
    //     name: 'register',
    //     meta: { showNavbar: false },
    //     component: () => import('../views/RegisterView.vue')
    // },
    {
        path: '/password-reset/:token?',
        name: 'password-reset',
        props: ( route ) => {
            return {
                token: route.params.token
            }
        },
        meta: { showNavbar: false },
        component: () => import('../views/PasswordReset.vue')
    },
]