<template>
    <h4 class="mb-3">Métodos de envío</h4>
    <div v-if="shipping">
        <div v-for="method in shipping.shipping_methods" :key="method.id" class="pointer shadow-sm rounded bg-light p-2 mb-2">
            <div class="d-flex" @click="chStore.setShippingMethod(method)">
                <template v-if="method.enabled">
                    <i class="bi me-2" :class="method.id === chStore.getShippingMethod?.id ? 'bi-check-circle text-success' : 'bi-circle'"></i>
                    <label class="form-check-label" :class="method.id === chStore.getShippingMethod?.id ? 'fw-bold' : ''" :for="`shipping-method-${method.id}`">
                        <span class="me-1">{{ method.title }}</span>
                        <span v-if="method.method_id == 'flat_rate'">{{ currencyFormatted(chStore.getShippingCost) }}</span>
                        <p class="mb-0 text-muted small" v-html="method.method_description.replace(/<\/?[^>]+(>|$)/g, '')" />
                    </label>
                </template>
            </div>
        </div>

        <div v-if="shipping.shipping_methods.length === 0" class="d-flex justify-content-center align-items-center bg-light p-5 rounded">
            <div class="spinner-border me-1 ms-3 spinner-border-sm" role="status">
                <span class="visually-hidden">Cargando</span>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="text-center bg-light p-5 rounded">
            <i class="bi bi-info-circle me-1 h2"></i>
            <p class="mb-0 mt-3">Completa los campos del formulario para seleccionar un método de envío.</p>
        </div>
    </div>
</template>

<script setup>
    import { currencyFormatted } from "@/helpers/currency";
    import { useCheckoutStore } from "@/stores/checkout";
    
    const chStore = useCheckoutStore()
    const props = defineProps({
        shipping: {
            required: true
        }
    })
</script>

<style scoped>
    label.fw-bold > span {
        font-weight: bold;
    }
</style>