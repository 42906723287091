import { defineStore } from 'pinia'
import { ref } from 'vue'
import server from '../api/server'
import { getRealPrice, getTaxImport } from '@/utils/product'

export const useProductsStore = defineStore('productsStore', () => {
    const isLoading = ref(false)
    const isCategorySelected = ref(false)
    const products = ref([])
    const defaultParams = {
        per_page: 50,
        status: 'publish',
        orderby: 'date'
    }

    async function fetchProducts(params = {}) {
        if(isLoading.value)
            return

        try {
            isLoading.value = true
            params = { ...defaultParams, ...params }
            isCategorySelected.value = params?.category

            const { data } = await server.get(`/stripe-payment-gateway/v1/products`, { params })

            products.value = data.results.map(product => {
                const variations = product.variations?.map(variation => buildProductObject(variation, product)) || []

                return {
                    ...buildProductObject(product),
                    variations
                }
            })
            
            isLoading.value = false
        } catch(err) {
            console.log(err, 'error')
            return false
        }
    }

    function setProducts(args) {
        products.value = args
    }

    function buildProductObject(data, parent = null) {
        const {regular_price, sale_price} = getRealPrice(data)
        const price = !sale_price ? regular_price : sale_price
        const taxAmount = getTaxImport(parent ? parent : data, price)

        const prices = {
            tax_amount: taxAmount,
            regular_price,
            sale_price,
            price,
            total: (taxAmount + price)
        }

        return {
            ...data,
            prices: prices,
            images: [{ src: data.image, id: 'featured_image' }]
        }
    }

    return { 
        isLoading,
        products,    
        isCategorySelected,
        fetchProducts, 
        setProducts,
    }
})
