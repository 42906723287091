<template>
    <transition name="fade" appear>
        <div class="modal in" :key="display" :class="display ? 'd-block show fade' : 'd-none'" tabindex="-1" @click.self="closeModal()">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content border-0 shadow-lg">
                    <div class="modal-header border-0">
                        <div class="modal-title"></div>
                        
                        <button type="button" 
                            class="btn py-2 btn-outline-secondary rounded-circle" 
                            @click="closeModal()">
                            <i class="bi bi-x"></i>
                        </button>
                    </div>

                    <div class="modal-body pb-5">
                        <div class="d-block d-md-flex">
                            <div class="text-center w-100">
                                <img class="rounded img-fluid product-image" :src="product.image || product.images[0].src" alt="Product Image">
                            </div>
                            <div class="product-content w-100 p-2">
                                <div class="product-info">
                                    <h5 class="mb-2 fw-bold">{{ product.name }}</h5>

                                    <template v-if="globalStore.status === 'authorised'">

                                        <select v-if="product.variations.length > 0" class="w-100 form-control my-2" v-model="variationSelected" name="variation" id="variation">
                                            <option value="">Selecciona una opción</option>
                                            <option v-for="(variation) in product.variations" :key="variation.id" :value="variation.id">{{ variation.name }}</option>
                                        </select>

                                        <div v-if="product.variations.length > 0 && getVariation">
                                            <span class="text-muted text-decoration-line-through me-1 small" v-if="getVariation.prices.sale_price">
                                                {{ currencyFormatted(getVariation.prices.regular_price) }}
                                            </span>
                                            
                                            <span class="text-theme">
                                                {{ getVariation.prices.sale_price 
                                                    ? currencyFormatted(getVariation.prices.sale_price)
                                                    : currencyFormatted(getVariation.prices.regular_price)
                                                }}
                                            </span>
                                        </div>

                                        <div v-if="product.variations.length === 0">
                                            <span class="text-muted text-decoration-line-through me-1 small" v-if="product.prices.sale_price">
                                                {{ currencyFormatted(product.prices.regular_price) }}
                                            </span>
                                            
                                            <span class="text-theme">
                                                {{ product.prices.sale_price 
                                                    ? currencyFormatted(product.prices.sale_price)
                                                    : currencyFormatted(product.prices.regular_price)
                                                }}
                                            </span>
                                        </div>
                                    </template>
                                    
                                    <div class="mt-3" v-html="product.description" />
                                </div>
                                <div class="d-flex">
                                    <button class="btn btn-theme btn" @click="handleCart()">
                                        {{
                                            globalStore.status !== 'authorised' ? 'Debes iniciar sesión' : 'Añadir al carrito'
                                        }} <i class="bi bi-cart"></i>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
import { currencyFormatted } from '@/helpers/currency';
import { useGlobalStore } from '@/stores/globalStore';
import { useRouter } from 'vue-router';
import { ref, computed } from 'vue'

const globalStore = useGlobalStore()
const router = useRouter()
const variationSelected = ref("")
const emits = defineEmits(['on-close', 'add-to-cart'])
const props = defineProps({
    display: {
        required: true,
        type: Boolean,
    },
    product: {
        required: true,
        type: Object,
    }
})
const getVariation = computed(() => props.product.variations.find(el => el.id === variationSelected.value))

function handleCart() {
    if(globalStore.status !== 'authorised')
        return router.push({name: 'login'})

    if(props.product.variations.length > 0 && !getVariation.value)
        return

    if(getVariation.value) {
        emits('add-to-cart', getVariation.value)
        emits('on-close')
        return
    }
    
    emits('add-to-cart', props.product)
    emits('on-close')
}

function closeModal() {
    emits('on-close')
}


</script>

<style scoped>
.product-image {
    object-fit: cover;
    height: 200px;
}
</style>