<template>
    <div class="col-md-5 col-lg-4">
        <div class="card border-0">
            <div class="card-body">
                <h4 class="my-3">Carrito</h4>
                <hr>
                <ul class="list-group mb-3">
                    <li v-for="item in chStore.getCart" :key="item.id" class="list-group-item border-0 d-flex justify-content-between align-items-center lh-sm">
                        <div>
                            <h6 class="my-0">{{ item.name }}</h6>
                            <small class="text-muted">Cantidad: {{ item.quantity }}</small>
                        </div>
                        <span class="text-muted">{{ currencyFormatted(parseFloat(item.prices.price * item.quantity)) }}</span>
                    </li>
                    <li class="list-group-item border-0 border-bottom border-top d-flex justify-content-between bg-light" v-if="coupon?.code">
                        <div class="text-success">
                            <h6 class="my-0">Código de descuento</h6>
                            <small class="text-uppercase">
                                {{coupon.code}}
                            </small>
                        </div>
                        <span class="text-success text-end">
                            <h6 class="my-0">
                                <template v-if="coupon.discount_type == 'percent'">{{parseInt(coupon.amount)}}%</template>
                                <template v-if="coupon.discount_type == 'fixed_cart'">{{currencyFormatted(parseFloat(coupon.amount))}}</template>
                            </h6>
                            <button class="btn btn-link text-theme btn-sm text-decoration-none p-0" @click="handleCoupon()">
                                Eliminar
                            </button>
                        </span>
                    </li>
                    <li class="list-group-item border-0 d-flex justify-content-between mt-4">
                        <span class="text-muted">Subtotal</span>
                        <span>{{ currencyFormatted(chStore.getSubtotal) }}</span>
                    </li>
                    <li v-if="chStore.getShippingMethod?.id" class="list-group-item border-0 d-flex justify-content-between">
                        <span class="text-muted">Envío: {{ chStore.getShippingMethod.title }}</span>
                        <span>{{ currencyFormatted(chStore.getShippingCost) }}</span>
                    </li>
                    <li class="list-group-item border-0 d-flex justify-content-between" v-if="chStore.getTax != 0">
                        <span class="text-muted">Impuestos</span>
                        <span>{{ currencyFormatted(chStore.getTax) }}</span>
                    </li>
                    <li v-if="coupon?.code" class="list-group-item border-0 d-flex justify-content-between">
                        <span class="text-muted">Descuento</span>
                        <span class="text-success">-{{ currencyFormatted(chStore.getDiscount) }}</span>
                    </li>
                    <li class="list-group-item border-0 d-flex justify-content-between">
                        <span class="fw-bold">Total</span>
                        <strong>{{ currencyFormatted(chStore.getTotal) }}</strong>
                    </li>
                </ul>

                <div class="input-group px-2" v-if="!chStore.getOrderPending">
                    <input type="text" v-model="inputCoupon" class="form-control" placeholder="Promo code">
                    <button type="button" class="btn btn-dark" :disabled="loading ? true : false" @click="handleCoupon()">
                        <span v-if="!loading">Canjear</span>
                        <span v-else>Aplicando</span>

                        <div v-if="loading" class="spinner-border me-1 ms-3 spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Cargando</span>
                        </div>
                    </button>
                </div>
                <div class="m-2 alert alert-danger alert-dismissible border-0 fade show shadow" v-if="errMessage" role="alert">
                    <small>{{errMessage}}</small>
                    <button type="button" class="btn-close text-theme" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { currencyFormatted } from '@/helpers/currency'
import { useCheckoutStore } from '@/stores/checkout'
import { onBeforeMount, ref } from 'vue'
const chStore = useCheckoutStore()

const errMessage = ref(null)
const inputCoupon = ref('')
const coupon = ref(null)
const loading = ref(false)

onBeforeMount( () => {
    coupon.value = {...chStore.getCoupon}
})

async function handleCoupon() {
    errMessage.value = null
    loading.value = true
    coupon.value = await chStore.setCoupon(inputCoupon.value)
    
    if(!coupon.value)
        errMessage.value = 'Este código de descuento no éxiste o no está disponible...'

    inputCoupon.value = ''
    loading.value = false
}
</script>

<style scoped>
.list-group {
    max-height: 635px;
    overflow-y: scroll;
}
</style>