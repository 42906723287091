<template>
   <footer class="d-flex d-lg-none rounded justify-content-around px-4 shadow-lg bg-white">
        <div class="footer-item text-center mt-2">
            <router-link class="footer-item-link text-center pointer text-muted" :to="{name: 'welcome'}">
                <i class="bi bi-cart4"></i>
                <span class="mt-1">Catálogo</span>
            </router-link>
        </div>
        <div></div>
        <div class="footer-item text-center mt-2">
            <router-link class="footer-item-link text-center pointer text-muted" :to="{name: 'account'}">
                <i class="bi bi-person"></i>
                <span class="mt-1">Perfil</span>
            </router-link>
        </div>
        <div class="footer-item text-center mt-2 position-absolute shadow">
            <a class="footer-item-link text-center pointer text-muted" href="javascript:void(0)" @click="handleShowFilters()">
                <transition name="list-vertical">
                    <i :key="showFilters" class="bi" :class="showFilters ? 'bi-x' : 'bi-sliders'"></i>
                </transition>
            </a>
        </div>
    </footer>

    <transition name="fade" appear>
        <div v-if="showFilters"  class="filters-container shadow-lg rounded">
            <header-link 
                class="px-3"
                :items="navbar" 
                @on-change="selectRoute" />
            <hr class="sidebar-divider">
            <header-categories @on-select="handleShowFilters()" />
            <div class="p-5"></div>
        </div>
    </transition>

    
    
    <div class="bg-white p-3 d-none d-lg-block" v-if="!prStore.isLoading">
        <ul class="nav justify-content-center border-bottom pb-3 mb-3">
            <li class="nav-item"><a target="_blank" href="https://almomento.cat/" class="nav-link px-2 text-muted">Página web</a></li>
            <li class="nav-item"><a target="_blank" href="https://almomento.cat/quienes-somos/" class="nav-link px-2 text-muted">Quienes somos</a></li>
            <li class="nav-item"><a target="_blank" href="https://almomento.cat/contacto/" class="nav-link px-2 text-muted">Contacto</a></li>
            <li class="nav-item"><a target="_blank" href="https://almomento.cat/aviso-legal/" class="nav-link px-2 text-muted">Aviso legal</a></li>
            <li class="nav-item"><a target="_blank" href="https://almomento.cat/politica-de-privacidad-2/" class="nav-link px-2 text-muted">Política de privacidad</a></li>
        </ul>
        <div class="text-center mb-2">
            <img class="kit-digital" src="@/assets/images/firma_kitdigital.png" alt="Kit Digital">
        </div>
        <p class="text-center text-muted mb-0">© {{ moment(new Date()).format('YYYY') }} www.almomento.cat | Versión {{ appVersion || "Alpha" }}</p>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useProductsStore } from '@/stores/products';
import {useRouter} from 'vue-router'
import HeaderCategories from '../categories/HeaderCategories.vue';
import HeaderLink from '@/components/common/HeaderLink.vue'
import moment from 'moment';

const prStore = useProductsStore()
const showFilters = ref(false)
const router = useRouter()
const appVersion = process.env.VUE_APP_VERSION

const handleShowFilters = () => {
    showFilters.value = !showFilters.value
}

const selectRoute = (item) => {
    navbar.value.map( item => {
        item.active = false
        return item
    })

    item.active = true
    router.push({ name: item.route })
    showFilters.value = false
}

const navbar = ref([
    {
        route: 'welcome',
        label: 'Catálogo',
        active: true,
        icon: 'bi bi-basket2',
    },
    {
        route: 'onsale',
        label: 'Promociones',
        active: false,
        icon: 'bi bi-percent',
    },
    {
        route: 'best-sellers',
        label: 'Más vendido',
        active: false,
        icon: 'bi bi-graph-up-arrow',
    },
    {
        route: 'featured',
        label: 'En liquidación',
        active: false,
        icon: 'bi bi-star',
    },
])
</script>

<style scoped>
.filters-container {
    position: fixed;
    bottom: 0px;
    min-height: 400px;
    background-color: white;
    width: calc(100% - 20px);
    height: 500px;
    overflow-y: scroll;
    padding: 20px;
    margin: 10px;
}
footer {
    z-index: 99;
    bottom: 10px;
    position: fixed;
    height: 70px;
    margin-left: 10px;
    width: calc(100% - 20px);
}

.white-space {
    width: 100px;
}
.position-absolute {
    top: -25px;
    background-color: #fff;
    border: 2px solid #ed8600;
    padding: 10px;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}
.position-absolute span,
.position-absolute i {
    color: #ed8600;
}

.footer-item {
    font-size: 24px;
}

.footer-item-link span {
    font-size: 12px;
    display: block;
    line-height: 6px;
}

.footer-item-link {
    text-decoration: none;
}

.btn-center {
	height: 60px;
	width: 60px;
    font-size: 26px;
	text-align: center;
	z-index: 9;
	left: 50%;
	transform: translate(-50%, -60%);
}

.kit-digital {
    max-width: 520px;
}
</style>