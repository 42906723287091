import Swal from 'sweetalert2'

export const swalLoading = (title = 'Procesando...', text = 'La solicitud se está procesando') => {
    Swal.fire({
        title,
        text,
        html: `<div class="p-5 text-center d-flex justify-content-center align-items-center h-100">
            <div id="loading-icon">
                <i class="mdi mdi-email"></i>
            </div>
        </div>`,
        showConfirmButton: false,
    })
}

export const swalClose = () => {
    Swal.close()
}

export const swalError = (title, errors) => {
    Swal.fire({
        icon: 'error',
        title,
        html: `<strong class="text-danger"> ${ errors[0] }</strong>`,
        showConfirmButton: false,
        timer: 3000
    })
}

export const swalSuccess = (title, text = '', timer = 2000) => {
    Swal.fire({
        icon: 'success',
        title,
        text,
        showConfirmButton: false,
        timer
    })
}

export const swalToast = (title = 'Los cambios se han actualizado con éxito', icon = 'success', timer = 2000, text = "") => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end', // Can be 'top', 'top-start', 'top-end', 'center', 'center-start', 'center-end', 'bottom', 'bottom-start', or 'bottom-end'
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mousedown', Swal.close)
        }
    })

    Toast.fire({
        icon,
        title,
        text
    })
}

export const swalQuestion = async(title, text) => {
    const { isConfirmed } = await Swal.fire({
        title,
        text,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar'
    })

    return isConfirmed
}

export const swalQuestionCustom = async(title, text, c_button, d_button, icon = 'info') => {
    const { isConfirmed } = await Swal.fire({
        title,
        text,
        icon,
        showDenyButton: true,
        confirmButtonText: c_button,
        denyButtonText: d_button
    })

    return isConfirmed
}


export const swalInputText = async(title, inputLabel, inputPlaceholder, c_button, d_button) => {
    const { value } = await Swal.fire({
        title,
        input: 'text',
        inputLabel,
        inputPlaceholder,
        showCancelButton: true,
        confirmButtonText: c_button,
        denyButtonText: d_button
    })

    return value
}

export const swalTimer = async(timer = 3000) => {
    let timerInterval

    await Swal.fire({
      title: 'Procesado transacción',
      html: 'Por favor espere mientras la petición se procesa.',
      timer,
      timerProgressBar: true,
      showCancelButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    })
}