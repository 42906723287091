<template>
    <div class="d-flex justify-content-between mb-3">
        <p class="text-muted mb-0">Subtotal</p>
        <p class="text-muted mb-0">{{ currencyFormatted(store.getSubtotal) }}</p>
    </div>

    <div class="d-flex justify-content-between mb-3" v-if="store.getShippingMethod">
        <p class="text-muted mb-0">Envío: {{ store.getShippingMethod.title }}</p>
        <p class="text-muted mb-0">{{ currencyFormatted(store.getShippingCost)}}</p>
    </div>

    <div class="d-flex justify-content-between mb-3" v-if="store.getTax != 0">
        <p class="text-muted mb-0">Impuestos</p>
        <p class="text-muted mb-0">{{ currencyFormatted(store.getTax) }}</p>
    </div>

    <div class="d-flex justify-content-between mb-3" v-if="store.getCoupon?.code">
        <p class="text-muted mb-0">Descuento <span class="text-uppercase">{{ store.getCoupon.code }}</span></p>
        <p class="text-success mb-0">-{{ currencyFormatted(store.getDiscount) }}</p>
    </div>

    <hr>

    <div class="d-flex justify-content-between mb-3">
        <p class="fw-bold mb-0">Total</p>
        <p class="text-muted mb-0">{{ currencyFormatted(store.getTotal) }}</p>
    </div>

    <button type="button" class="btn btn-theme w-100 fw-bold shadow" @click="handleCheckout()">Finalizar compra</button>
</template>

<script setup>
import { currencyFormatted } from "@/helpers/currency";
import { useCheckoutStore } from "@/stores/checkout"
const store = useCheckoutStore()
const emits = defineEmits(['navigate-checkout'])

function handleCheckout() {
    if(store.getCart.length === 0) 
        return
        
    emits('navigate-checkout')
}
</script>

<style scoped>
    .image-product {
        height: 70px;
        width: 70px;
        object-fit: cover;
    }
</style>